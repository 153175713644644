import axios from "@/plugins/axios";
import { isAxiosError } from "@/plugins/Error";
import { assertIsDefined } from "@/utils/asserts";
import { ComboboxOption, SearchService } from ".";

const Paths = {
  List: "api/ReportTemplate",
  Get: (id: string, isForce: boolean | undefined = undefined) =>
    `api/ReportTemplate/${id}?isAll=true${
      isForce === true ? "&isForce=true" : ""
    }`,
  Delete: (id: string) => `api/ReportTemplate/${id}`,
  Create: "api/ReportTemplate",
  Save: (id: string) => `api/ReportTemplate/${id}`,
  CloudUpload: (id: string) => `api/ReportTemplate/${id}/synchronize`,
  EquipmentList: "api/Equipment/combobox",
  Template: (id: string) => `api/ReportTemplate/${id}?isAll=true`,
  Download: (id: string) => `api/ReportTemplate/${id}/download`,
  GetTemplateInformation: "api/ReportTemplate/template-information",
};
/**
 * 検索パラメータ
 */
export type SearchParams = {
  equipmentId: string;
  fileName: string;
  isUploaded: string;
  orderBy: string;
  isAll: string;
};
/**
 * 報告書テンプレートモデル
 */
export type TemplateModel = {
  id: string;
  equipmentId: string;
  fileName: string;
  filePath: string;
  useAdditional: boolean;
  isProject: boolean;
  tag: string;
  createAt: string;
  createUserId: string;
  updateAt: string;
  updateUserId: string;
  uploadAt: string;
  isDeleted: boolean;
  options: TemplateOptionModel[];
  equipment: EquipmentModel;
  hasMeasurement: boolean;
};
export type CreateTemplateModel = {
  equipmentId: string;
  fileName: string;
  file?: File;
  useAdditional: boolean;
  isProject: boolean;
  tag: string;
  options: CreateTemplateOptionModel[];
};
export type CreateTemplateOptionModel = {
  title: string;
  title2: string;
  type: number;
  order: number;
};
/**
 * テンプレートオプションモデル
 */
export type TemplateOptionModel = {
  id: string;
  templateId: string;
  title: string;
  title2: string;
  type: number;
  order: number;
  isDeleted: boolean;
};
/**
 * 機器モデル
 */
export type EquipmentModel = {
  id: string;
  name: string;
};
export type TemplateInformation = {
  openableFile: boolean;
};
/**
 * 報告書テンプレート検索サービス
 */
export class TemplateService extends SearchService<
  SearchParams,
  TemplateModel
> {
  constructor() {
    super({ omit: ["detail", "new"] });
  }
  protected get getPath(): string {
    return Paths.List;
  }
  async getEquipments(
    option?: { isAll: string },
    options?: { signal: AbortSignal }
  ): Promise<ComboboxOption[]> {
    try {
      const record = (option ?? {}) as Record<string, string>;
      const query = new URLSearchParams(record).toString();
      const response = await axios.get<ComboboxOption[]>(
        `${Paths.EquipmentList}?${query}`,
        options
      );
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  async get(id: string, isForce?: boolean): Promise<TemplateModel> {
    try {
      const url = Paths.Get(id, isForce);
      const response = await axios.get<TemplateModel>(url);
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  async cloudUpload(id: string): Promise<boolean> {
    try {
      const url = Paths.CloudUpload(id);
      await axios.post(url);
      return true;
    } catch (e) {
      console.error(e);
      if (!isAxiosError(e)) throw e;
      return false;
    }
  }
  /**
   * 報告書テンプレートを作成する
   * @param model
   * @param options
   * @returns
   */
  async create(
    model: CreateTemplateModel,
    options?: { signal?: AbortSignal }
  ): Promise<TemplateModel> {
    try {
      const m = Object.assign({}, model);
      assertIsDefined(m.file);
      const file = m.file;
      delete m.file;
      const url = Paths.Create;
      const form = new FormData();
      const json = JSON.stringify(m);
      console.debug("create use json :%o", json);
      form.append("file", file);
      form.append("json", json);
      const response = await axios.post<TemplateModel>(url, form, options);
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  /**
   * 報告書テンプレートを更新する
   * @param model
   * @param options
   */
  async update(
    model: CreateTemplateModel & { id: string },
    options?: { signal?: AbortSignal }
  ): Promise<void> {
    try {
      const url = Paths.Save(model.id);
      await axios.put(url, model, options);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  async delete(id: string, options?: { signal?: AbortSignal }): Promise<void> {
    try {
      const url = Paths.Delete(id);
      await axios.delete(url, options);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  /**
   * 報告書テンプレートファイルより、ファイル情報の取得を行う
   * @param file
   * @param options
   * @returns
   */
  async getTemplateInformation(
    file: File,
    equipmentId?: string,
    options?: { signal: AbortSignal }
  ): Promise<TemplateInformation> {
    try {
      const url = Paths.GetTemplateInformation;
      const form = new FormData();
      form.set("file", file);
      form.set("fileName", file.name.normalize());
      if (equipmentId) form.set("equipmentId", equipmentId);
      const response = await axios.post<TemplateInformation>(
        url,
        form,
        options
      );
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}
export const Template = new TemplateService();

export function getDownloadUrl(model: TemplateModel): string {
  return Paths.Download(model.id);
}
